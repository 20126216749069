import {FormikErrors, FormikValues} from "formik";
import {FormFieldType} from "enum/FormFieldType";
import Platform from "platform/Platform";
import Utils from "platform/util/Utils";
import {TradeType} from "protocol/trade/TradeType";
import {Ranges} from "protocol/trade/Ranges";
import {PositionDirection} from "platform/protocol/enum/PositionDirection";
import Translations from "platform/translation/Translations";
import {TranslationKey} from "enum/TranslationKey";
import {DealForm} from "core/redux/deal/DealReduxState";
import {Configuration, PasswordRuleConfig} from "core/configuration/Configuration";
import {IFormPasswordError} from "core/redux/login/LoginReduxState";
import {SymbolDetails} from "protocol/symbol/GetSymbolDetailsResponse";
import {SignUpSocialDetails} from "protocol/auth/SignUpRequest";
import {SocialMediaType} from "enum/SocialMediaType";

export class Validation {

    public static validateDeal(fields: FormFieldType[], form: DealForm, ranges: Ranges, symbolDetails: SymbolDetails): {
        errors: {[key: string]: any},
        uiErrors: {[key: string]: any}
    } {
        const errors: {[key: string]: any} = {};
        const uiErrors: {[key: string]: any} = {};
        if (fields) {
            fields.forEach((fieldType: FormFieldType) => {
                switch (fieldType) {
                    case FormFieldType.Amount:
                        const amount: number = form[fieldType];
                        if (!Utils.greaterThen0(amount)) {
                            errors[fieldType] = "Required";
                        } else if (!Utils.greaterThen0(form.PositionId) || form.RateRequestType === TradeType.EntryOrder) {
                            const minAmount: number = symbolDetails?.MinimalAmount;
                            const maxAmount: number = symbolDetails?.MaximalAmount;
                            if (minAmount > amount) {
                                errors[fieldType] = uiErrors[fieldType] = "Amount lower then min amount: " + minAmount;
                            } else if (maxAmount < amount) {
                                errors[fieldType] = uiErrors[fieldType] = "Amount greater then max amount: " + maxAmount;
                            }
                        }
                        break;
                    case FormFieldType.SymbolId:
                    case FormFieldType.PositionDirection:
                        if (!Utils.greaterThen0(form[fieldType])) {
                            errors[fieldType] = "Required";
                        }
                        break;
                    case FormFieldType.RateRequestType:
                        if (!Utils.greaterThen0(form[fieldType])) {
                            errors[fieldType] = "Required";
                        } else {
                            Validation.validateOrderRate(fields, form, ranges, errors, uiErrors);
                        }
                        break;
                    case FormFieldType.OrderRate:
                        Validation.validateOrderRate(fields, form, ranges, errors, uiErrors);
                        break;
                    case FormFieldType.OCOChecked:
                    case FormFieldType.OCOValueRate:
                        const {OCOChecked, OCORate} = form;
                        if (OCOChecked) {
                            const {EntryLimitMinimum, EntryLimitMaximum} = ranges;
                            const outOfRange: boolean = OCORate < EntryLimitMinimum || OCORate > EntryLimitMaximum;
                            if (Utils.isNull(OCORate) || outOfRange) {
                                errors[FormFieldType.OCOValueRate] = "OCO rate out of range";
                                if (outOfRange) {
                                    uiErrors[FormFieldType.OCOValueRate] = errors[FormFieldType.OCOValueRate];
                                }
                            }
                        }
                        break;
                    case FormFieldType.StopLossChecked:
                    case FormFieldType.StopLossValueRate:
                    case FormFieldType.StopLossValueAmount:
                        const {StopLossValueRateOrigin, StopLossValueAmountOrigin, HasUserEnteredStopLossAmount} = form;
                        const {StopLossValueRate, StopLossValueAmount, StopLossMinimum, StopLossMaximum} = ranges;
                        if (form[FormFieldType.StopLossChecked]) {
                            const slOutOfRange: boolean = StopLossValueRate < StopLossMinimum || StopLossValueRate > StopLossMaximum;
                            if (Utils.isNull(StopLossValueRate) || (slOutOfRange && ((!HasUserEnteredStopLossAmount && StopLossValueRateOrigin !== StopLossValueRate) ||
                                    (HasUserEnteredStopLossAmount && StopLossValueAmountOrigin !== StopLossValueAmount)))) {
                                errors[FormFieldType.StopLossValueRate] = "Stop loss rate out of range";
                                if (Utils.isNotNull(StopLossValueRate)) {
                                    uiErrors[FormFieldType.StopLossValueRate] = errors[FormFieldType.StopLossValueRate];
                                }
                            }
                        }
                        break;
                    case FormFieldType.TakeProfitChecked:
                    case FormFieldType.TakeProfitValueRate:
                    case FormFieldType.TakeProfitValueAmount:
                        const {TakeProfitValueRateOrigin, TakeProfitValueAmountOrigin, HasUserEnteredTakeProfitAmount} = form;
                        const {TakeProfitValueRate, TakeProfitValueAmount, TakeProfitMinimum, TakeProfitMaximum} = ranges;
                        if (form[FormFieldType.TakeProfitChecked]) {
                            const tpOutOfRange: boolean = TakeProfitValueRate < TakeProfitMinimum || TakeProfitValueRate > TakeProfitMaximum;
                            if (Utils.isNull(TakeProfitValueRate) || (tpOutOfRange && ((!HasUserEnteredTakeProfitAmount && TakeProfitValueRateOrigin !== TakeProfitValueRate) ||
                                    (HasUserEnteredTakeProfitAmount && TakeProfitValueAmountOrigin !== TakeProfitValueAmount)))) {
                                errors[FormFieldType.TakeProfitValueRate] = "Take profit rate out of range";
                                if (Utils.isNotNull(TakeProfitValueRate)) {
                                    uiErrors[FormFieldType.TakeProfitValueRate] = errors[FormFieldType.TakeProfitValueRate];
                                }
                            }
                        }
                        break;
                    case FormFieldType.ExpirationChecked:
                    case FormFieldType.ExpirationDay:
                    case FormFieldType.ExpirationMonth:
                    case FormFieldType.ExpirationYear:
                        if (form[FormFieldType.ExpirationChecked]) {
                            const day: number = form[FormFieldType.ExpirationDay];
                            const month: number = form[FormFieldType.ExpirationMonth];
                            const year: number = form[FormFieldType.ExpirationYear];
                            if (!Utils.greaterThen0(day)) {
                                errors[FormFieldType.ExpirationDay] = "Required";
                            } else if (!Utils.greaterThen0(month)) {
                                errors[FormFieldType.ExpirationMonth] = "Required";
                            } else if (!Utils.greaterThen0(year)) {
                                errors[FormFieldType.ExpirationYear] = "Required";
                            }
                        }
                        break;
                }
            });
        }
        return {errors, uiErrors};
    }

    private static validateOrderRate<Form>(fields: FormFieldType[], form: DealForm, ranges: Ranges, errors: {[key: string]: any}, uiErrors: {[key: string]: any}): void {
        const tradeType: TradeType = form[FormFieldType.RateRequestType];
        if (tradeType === TradeType.EntryOrder) {
            const {OrderRateOrigin} = form;
            const orderRate: number = form[FormFieldType.OrderRate];
            const direction: PositionDirection = form[FormFieldType.PositionDirection];
            const {EntryLimitMinimum, EntryStopMaximum, EntryStopMinimum, EntryLimitMaximum} = ranges;
            const minimum: number = direction === PositionDirection.Sell ? EntryStopMaximum : EntryLimitMaximum;
            const maximum: number = direction === PositionDirection.Sell ?  EntryLimitMinimum : EntryStopMinimum;
            const outOfRange: boolean = orderRate < maximum && orderRate > minimum;
            const hasOrderRate: boolean = Utils.greaterThen0(orderRate);
            if (!hasOrderRate || (outOfRange && OrderRateOrigin !== orderRate)) {
                errors[FormFieldType.OrderRate] = "Order rate out of range";
                if (hasOrderRate) {
                    uiErrors[FormFieldType.OrderRate] = errors[FormFieldType.OrderRate];
                }
            }
        }
    }

    public static validateWithdrawal<Form>(values: FormikValues, WithdrawableEquity: number): FormikErrors<Form> {
        const errors: FormikErrors<Form> = {};
        const sAmount: string = Utils.nullToEmpty(values[FormFieldType.Amount]).replace(",", ".");
        const amount: number = parseFloat(sAmount);
        if (!Utils.greaterThen0(amount) || Utils.countDecimals(amount) > 2 || amount > WithdrawableEquity) {
            errors[FormFieldType.Amount] = "Invalid";
        }
        if (Utils.isEmpty(values[FormFieldType.Reason])) {
            errors[FormFieldType.Reason] = "Required";
        }
        return errors;
    }

    public static validatePassword<Form>(values: FormikValues, currentPsw?: string): FormikErrors<Form> {
        const errors: FormikErrors<Form> = {};
        const currentPassword: string = values[FormFieldType.CurrentPassword];
        const newPassword: string = values[FormFieldType.NewPassword];
        const confirmPassword: string = values[FormFieldType.ConfirmPassword];

        if (Utils.isEmpty(currentPassword)) {
            errors[FormFieldType.CurrentPassword] = Translations.text(TranslationKey.required);
        }
        if (currentPsw && currentPassword !== currentPsw) {
            errors[FormFieldType.CurrentPassword] = Translations.text(TranslationKey.errorPasswordsNotMatch);
        }
        if (Utils.isEmpty(newPassword)) {
            errors[FormFieldType.NewPassword] = Translations.text(TranslationKey.required);
        }
        if (Utils.isEmpty(confirmPassword)) {
            errors[FormFieldType.ConfirmPassword] = Translations.text(TranslationKey.required);
        }
        if (Utils.isNotEmpty(confirmPassword) && newPassword !== confirmPassword) {
            errors[FormFieldType.ConfirmPassword] = Translations.text(TranslationKey.errorPasswordsNotMatch);
        }
        return errors;
    }

    public static validateResetPassword<Form>(values: FormikValues): FormikErrors<Form> {
        const errors: FormikErrors<Form> = {};
        const newPassword: string = values[FormFieldType.NewPassword];
        const confirmPassword: string = values[FormFieldType.ConfirmPassword];

        if (Utils.isEmpty(newPassword)) {
            errors[FormFieldType.NewPassword] = "Please provide new password";
        } else if (newPassword.length < 8) {
            errors[FormFieldType.NewPassword] = "Password should be at least 8 characters";
        } else if (Utils.isEmpty(confirmPassword)) {
            errors[FormFieldType.ConfirmPassword] = "Please repeat your new password";
        } else if (newPassword !== confirmPassword) {
            errors[FormFieldType.ConfirmPassword] = Translations.text(TranslationKey.errorPasswordsNotMatch);
        }
        return errors;
    }

    public static validateChangePassword<Form>(values: FormikValues): FormikErrors<Form> {
        const {ResetPassword} = Platform.config<Configuration>();
        const errors: FormikErrors<Form> = {};

        const username: string = values[FormFieldType.Username];
        const currentPassword: string = values[FormFieldType.Password]
        const newPassword: string = values[FormFieldType.NewPassword];
        const confirmPassword: string = values[FormFieldType.ConfirmPassword];

        if (Utils.isEmpty(username)) {
            errors[FormFieldType.Username] = "Invalid";
        }
        if (Utils.isEmpty(currentPassword)) {
            errors[FormFieldType.Password] = "Invalid";
        }
        Validation.validateNewPassword(ResetPassword, newPassword, errors);
        if (!errors[FormFieldType.NewPassword] && Utils.isEmpty(confirmPassword)) {
            errors[FormFieldType.ConfirmPassword] = "Invalid";
        } else if (!errors[FormFieldType.NewPassword] && newPassword !== confirmPassword) {
            errors[FormFieldType.ConfirmPassword] = "Invalid";
        }
        return errors;
    }

    public static validateCloseOutMethod<Form>(values: FormikValues): FormikErrors<Form> {
        const errors: FormikErrors<Form> = {};
        const closeOutMethod: string = values[FormFieldType.CloseOutMethod];

        if (Utils.isEmpty(closeOutMethod)) {
            errors[FormFieldType.CloseOutMethod] = Translations.text(TranslationKey.errorModalRequired);
        }

       return errors;
    }

    public static validateMyLeverage<Form>(values: FormikValues): FormikErrors<Form> {
        const errors: FormikErrors<Form> = {};
        const value: string = values[FormFieldType.MyLeverageValue];

        if (Utils.isEmpty(value)) {
            errors[FormFieldType.MyLeverageValue] = Translations.text(TranslationKey.errorModalRequired);
        }

        return errors;
    }

    public static validateAnnualStatement<Form>(values: FormikValues): FormikErrors<Form> {
        const errors: FormikErrors<Form> = {};
        const value: string = values[FormFieldType.AnnualStatementYear];

        if (Utils.isEmpty(value)) {
            errors[FormFieldType.AnnualStatementYear] = Translations.text(TranslationKey.errorModalRequired);
        }

        return errors;
    }

    public static validateNewEmail<Form>(values: FormikValues): FormikErrors<Form> {
        const errors: FormikErrors<Form> = {};
        const value: string = values[FormFieldType.Email];
        const regExp: RegExp = new RegExp("(?!.*?[._%+-]{2,})(?=[a-zA-Z0-9@._%+-]{6,254}$)(^[a-zA-Z0-9])[a-zA-Z0-9._%+-]{1,64}([^._%+-]{1,})@(?!(gamail\.com)|(gamail\.top)|(gamil\.com)|(gmal\.com)|(gmail\.co$)|(gmail\.con)|(gmai\.com))(?:[a-zA-Z0-9-]{1,63}\\.){1,8}[a-zA-Z]{2,63}", "u");
        const valueMatch = value.match(regExp);

        if (Utils.isEmpty(value)) {
            errors[FormFieldType.Email] = Translations.text(TranslationKey.errorModalRequired);
        }
        if (!(valueMatch && value === valueMatch[0])) {
            errors[FormFieldType.Email] = Translations.text(TranslationKey.settingsProfileInvalidEmail);
        }
        return errors;
    }

    public static validateFeedback<Form>(values: FormikValues): FormikErrors<Form> {
        const errors: FormikErrors<Form> = {};
        const rating: number = values[FormFieldType.FeedbackRating];
        const comment: string = values[FormFieldType.FeedbackComment];

        if (rating === 0) {
            errors[FormFieldType.FeedbackRating] = Translations.text(TranslationKey.required);
        }

        if (comment.length > 4000) {
            errors[FormFieldType.FeedbackComment] = "Comment size has been exceeded";
        }

        return errors;
    }

    public static async validatePhoneNumber<Form>(values: FormikValues): Promise<FormikErrors<Form>> {
        const errors: FormikErrors<Form> = {};
        const phoneNumber: string = values[FormFieldType.PhoneNumber];
        const phoneCode: number = values[FormFieldType.PhoneNumberCountryCode]
        if (Utils.isEmpty(phoneNumber)) {
            errors[FormFieldType.PhoneNumber] = Translations.text(TranslationKey.required);
        }
        if (!phoneCode) {
            errors[FormFieldType.PhoneNumberCountryCode] = Translations.text(TranslationKey.required);
        }
        const awesomePhoneNumber = await import('awesome-phonenumber');
        try {
            const pn = awesomePhoneNumber.parsePhoneNumber(`+${phoneCode}${phoneNumber}`);
            if (!pn?.valid) {
                errors[FormFieldType.PhoneNumber] = Translations.text(TranslationKey.settingsProfileInvalidPhoneNumber);
            }
        } catch (err) {
            errors[FormFieldType.PhoneNumber] = "Invalid country code";
        }
        return Promise.resolve(errors);
    }

    public static validateLoginForm<Form>(values: FormikValues): FormikErrors<Form> {
        const errors: FormikErrors<Form> = {};
        const email: string = values[FormFieldType.Email];
        const password: string = values[FormFieldType.Password];
        if(Utils.isEmpty(email)) {
            errors[FormFieldType.Email] = "Invalid";
        } else if (Utils.isEmpty(password)) {
            errors[FormFieldType.Password] = "Invalid";
        }
        return errors;
    }

    public static validateNewPassword(rules: PasswordRuleConfig[], password: string, errors: FormikErrors<any>): void {
        if (Utils.isArrayNotEmpty(rules)) {
            const Errors: IFormPasswordError[] = [];

            rules.forEach((rule: PasswordRuleConfig) => {
                const regExp: RegExp = new RegExp(rule.pattern);
                Errors.push({
                    trKey: "signUp.password.error.RegEx" + rule.index,
                    rule: regExp.test(password) === rule.valid ? null : rule
                });
            });
            if (Errors.some(({rule}) => Utils.isNotNull(rule))) {
                errors[FormFieldType.NewPassword] = Errors as any;
            }
        }
    }

    public static async validateSignUpForm<Form>(
        values: FormikValues,
        errors: FormikErrors<any>,
        socialDetails: SignUpSocialDetails,
        useFullName: boolean,
        isAutoPassword: boolean
    ): Promise<void> {
        const {signUp} = Platform.config<Configuration>();

        const fullNameRegExp: RegExp = new RegExp(signUp.validation.name);
        const passwordRules = signUp.validation.password;

        const fullName: string = values[FormFieldType.FullName];
        const firstName: string = values[FormFieldType.FirstName];
        const lastName: string = values[FormFieldType.LastName];
        const password: string = values[FormFieldType.NewPassword];
        const hasReferralCode: boolean = values[FormFieldType.HasReferralCode];
        const referralCode: string = values[FormFieldType.ReferralCode];

        if (Utils.isNull(socialDetails)) {
            if (useFullName) {
                if (Utils.isEmpty(fullName) || !fullNameRegExp.test(fullName)) {
                    errors[FormFieldType.FullName] = "Invalid";
                }
            } else {
                if (Utils.isEmpty(firstName) || !fullNameRegExp.test(firstName)) {
                    errors[FormFieldType.FirstName] = "Invalid";
                }
                if (Utils.isEmpty(lastName) || !fullNameRegExp.test(lastName)) {
                    errors[FormFieldType.LastName] = "Invalid";
                }
            }
        } else if (socialDetails.socialType === SocialMediaType.Apple && Utils.isEmpty(socialDetails.firstName)) {
            if (Utils.isEmpty(firstName) || !fullNameRegExp.test(firstName)) {
                errors[FormFieldType.FirstName] = "Invalid";
            }
            if (Utils.isEmpty(lastName) || !fullNameRegExp.test(lastName)) {
                errors[FormFieldType.LastName] = "Invalid";
            }
        }
        const phoneErrors: FormikErrors<any> = await Validation.validatePhoneNumber(values);
        if (phoneErrors[FormFieldType.PhoneNumberCountryCode]) {
            errors[FormFieldType.PhoneNumberCountryCode] = phoneErrors[FormFieldType.PhoneNumberCountryCode];
        }
        if (phoneErrors[FormFieldType.PhoneNumber]) {
            errors[FormFieldType.PhoneNumber] = phoneErrors[FormFieldType.PhoneNumber];
        }
        if (Utils.isNull(socialDetails)) {
            const emailErrors: FormikErrors<any> = Validation.validateNewEmail(values);
            if (emailErrors[FormFieldType.Email]) {
                errors[FormFieldType.Email] = "Invalid";
            }
            if (!isAutoPassword) {
                Validation.validateNewPassword(passwordRules, password, errors);
            }
        }
        if (hasReferralCode && Utils.isEmpty(referralCode)) {
            errors[FormFieldType.ReferralCode] = "Invalid";
        }
    }
}
