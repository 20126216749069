import {Engine} from "platform/engine/Engine";
import {
    AcceptDisclaimerForExternalProvidersPayload,
    DoChangeCloseOutMethodPayload,
    DoChangeLeveragePayload,
    InsertClientPreference,
    InsertClientPreferenceLSPayload,
    InsertClientPreferencePayload,
    RegisterPushTokenPayload,
    SetAvailableCloseOutMethods,
    SetAvailableLeverages,
    SetCanSkipEducationTradingGroup,
    SetClientPreference,
    SetCurrentCloseOutMethod,
    SetCurrentLeverage, SetDeclarationRecorded,
    SetDepositLimitsInfo,
    SetDisclaimerAcceptedForExternalProviders,
    SetDisclaimerStatusReceivedForExternalProviders,
    SetEMTPercentages,
    SetIsRollingContractsEnabled,
    SetLegalDataDocuments,
    SetLocalizationData, SetTelegramUrl, SetTransactionsToSign,
    SetUserProfileInfo,
    SetVaultDetails,
    SubmitAccountAnnualReportPayload,
    SubmitLeverageDisclaimerPayload, TransactionsToSignPayload, UpdatePlatformLanguagePayload
} from "core/redux/settings/SettingsReduxActions";
import {AuthState} from "core/state/AuthState";
import Platform from "platform/Platform";
import {ServiceType} from "enum/ServiceType";
import {AccountState} from "core/state/AccountState";
import {ServerType} from "platform/enum/ServerType";
import {NXEnvironmentType} from "platform/protocol/enum/NXEnvironmentType";
import {PreferencesManager} from "core/engine/PreferencesManager";
import {Preference} from "core/util/Preference";
import {XhrUtil} from "core/util/XhrUtil";
import {HttpReject} from "platform/network/http/Http";
import Utils from "platform/util/Utils";
import {GetAccountLogRequest} from "protocol/account/GetAccountLogRequest";
import {ShowPopup} from "platform/redux/popups/PopupsActions";
import {PopupActionType, PopupIconType, PopupType} from "platform/redux/popups/PopupsReduxState";
import {TranslationKey} from "enum/TranslationKey";
import {TranslationParam} from "enum/TranslationParam";
import Parameter from "platform/util/Parameter";
import {StoreState} from "core/redux/StoreState";
import {GetChangeLeverageRequest} from "protocol/config/GetChangeLeverageRequest";
import {LangCode} from "platform/enum/LangCode";
import {LanguageUtil} from "platform/util/LanguageUtil";
import {GetChangeCloseOutMethodsRequest} from "protocol/config/GetChangeCloseOutMethodsRequest";
import {GetChangeLeverageResponse, Leverage} from "protocol/config/GetChangeLeverageResponse";
import {CloseOutMethod, GetChangeCloseOutMethodsResponse} from "protocol/config/GetChangeCloseOutMethodsResponse";
import {ChangeLeverageRequest} from "protocol/config/ChangeLeverageRequest";
import {ChangeCloseOutMethodRequest} from "protocol/config/ChangeCloseOutMethodRequest";
import {HideLoader, SetLoader, SetThemeTypePayload} from "platform/redux/core/CoreActions";
import {LoaderType} from "platform/enum/LoaderType";
import {SetLDPlatformPropsPayload, SetTradingModal} from "core/redux/app/AppReduxActions";
import TradingModalType from "enum/TradingModalType";
import {AgreeLeverageDisclaimerRequest} from "protocol/config/AgreeLeverageDisclaimerRequest";
import {GetDepositLimitsResponse} from "protocol/account/GetDepositLimitsResponse";
import {GetUserProfileResponse, UserProfileInfo} from "protocol/account/GetUserProfileResponse";
import {GetVaultDetailsResponse} from "protocol/promo/GetVaultDetailsResponse";
import {IsRollingEnabledResponse} from "protocol/account/IsRollingEnabledResponse";
import {LocalizationManager} from "core/engine/LocalizationManager";
import {SetAuthStatusPayload} from "core/redux/auth/AuthReduxActions";
import {LSKey} from "platform/storage/Storage";
import {LDDocument, LDDocumentsConfig} from "core/props/LDPlatformProps";
import {SignalsDisclaimerAgreementRequest} from "protocol/account/SignalsDisclaimerAgreementRequest";
import {SignalsDisclaimerAgreementResponse} from "protocol/account/SignalsDisclaimerAgreementResponse";
import {SkipEducationTradingGroupRequest} from "protocol/account/SkipEducationTradingGroupRequest";
import {AddPushNotificationTokenRequest} from "protocol/auth/AddPushNotificationTokenRequest";
import {GetPushNotificationDataRequest} from "protocol/auth/GetPushNotificationDataRequest";
import {GetPushNotificationDataResponse, PushMessage} from "protocol/auth/GetPushNotificationDataResponse";
import {AppState} from "core/state/AppState";
import {ReadyState} from "core/state/ReadyState";
import {NumberPayload} from "core/redux/StoreActions";
import {GetEMTPercentagesRequest} from "protocol/config/GetEMTPercentagesRequest";
import {SetEMTPercentageRequest} from "protocol/config/SetEMTPercentageRequest";
import {SetEMTPercentageResponse} from "protocol/config/SetEMTPercentageResponse";
import {BIEventType} from "enum/BIEventType";
import {GetPendingTransactionsToSignResponse} from "protocol/account/GetPendingTransactionsToSignResponse";
import {SignTransactionsRequest} from "protocol/account/SignTransactionsRequest";
import {SignTransactionsResponse} from "protocol/account/SignTransactionsResponse";
import {GetVaultDetailsRequest} from "protocol/promo/GetVaultDetailsRequest";
import {GetTelegramInitURLResponse} from "protocol/account/GetTelegramInitURLResponse";
import {UpdatePlatformLanguageRequest} from "protocol/account/UpdatePlatformLanguageRequest";

export default class SettingsEngine extends Engine {

    private static _instance: SettingsEngine;

    public static instance(): SettingsEngine {
        return this._instance || (this._instance = new this());
    }

    public async setup(): Promise<void> {
        await super.setup();
    }

    public doSetAuthStatus = async (payload: SetAuthStatusPayload) => {
        const {connected, authenticated, accountType} = Platform.reduxState<StoreState>().auth;
        if (connected) {
            const Authenticated: boolean = !authenticated && payload.authStatus.authenticated;
            if (Authenticated) {
                const appState: AppState = Platform.state(ServiceType.App);
                if (appState.nativePushNotificationToken) {
                    this.doRegisterPushToken({
                        platformType: appState.nativePlatformType,
                        packageName: appState.nativePackageName,
                        pushNotificationToken: appState.nativePushNotificationToken
                    }).catch(() => {});
                } else {
                    this._logger.warn("On Authenticated. Native push token absent in AppState.");
                }
                this.doFetchUserProfile().catch(() => {});
                this.doFetchTelegramInitUrl().catch(() => {});
            //     make request for Telegram
            }
            const accountState: AccountState = Platform.state(ServiceType.Account);
            if (accountState.accountType === NXEnvironmentType.Live &&
                    (Authenticated || (accountType && payload.authStatus.accountType && accountType !== payload.authStatus.accountType))) {
                const servers: {[key: string]: string} = Platform.config().servers;
                Utils.checkNotNull(servers);
                const origin: string = servers[ServerType.TradingReal];
                const langCode: LangCode = LanguageUtil.languageCode();
                const authState: AuthState = Platform.state(ServiceType.Auth);
                const localization: {[key: string]: string} = await LocalizationManager.FetchLocalizations(langCode, origin, authState.token);
                Platform.dispatch(SetLocalizationData({localization}));
            }
        }
    }

    public onSetLDPlatformProps = ({props}: SetLDPlatformPropsPayload): void => {
        if (props?.keys?.LegalDataDocuments) {
            try {
                const config: LDDocumentsConfig = JSON.parse(props?.keys?.LegalDataDocuments.value);
                if (Utils.isArrayNotEmpty(config?.documents)) {
                    Platform.dispatch(SetLegalDataDocuments({
                        documents: config.documents.sort((d1: LDDocument, d2: LDDocument) => Utils.compareString(d1?.title, d2?.title))
                    }));
                }
            } catch (e) {
                this._logger.debug("Failed parse LD documents config");
            }
        }
    }

    public doFetchDepositLimits = async (): Promise<void> => {
        const accountState: AccountState = Platform.state(ServiceType.Account);
        if (accountState.accountType === NXEnvironmentType.Live) {
            const answer: [HttpReject, GetDepositLimitsResponse] = await Utils.to(this.sendToWebProfitService(null, "GetUserDepositLimits"));
            if (answer[0]) {
                this._logger.debug("Failed fetch deposit limits");
            } else {
                const MaxNetDepositsAmount: number = Utils.nullTo0(answer[1]?.MaxNetDepositsAmount);
                const TotalDepositsAmount: number = Utils.nullTo0(answer[1]?.TotalDepositsAmount);
                Platform.dispatch(SetDepositLimitsInfo({
                    accountLimit: MaxNetDepositsAmount,
                    leftTopUp: parseFloat((MaxNetDepositsAmount - TotalDepositsAmount).toFixed(2))
                }))
            }
        }
    }

    public doFetchUserProfile = async (): Promise<UserProfileInfo> => {
        const answer: [HttpReject, GetUserProfileResponse] = await Utils.to(this.sendToWebProfitService(null, "GetUserProfileConfiguration"));
        if (answer[0]) {
            this._logger.debug("Failed fetch user profile");
        } else {
            const userProfile: UserProfileInfo = answer[1] || {};
            const accountState: AccountState = Platform.state(ServiceType.Account);
            if (accountState.accountType === NXEnvironmentType.Demo) {
                userProfile.IsSignalsDisclaimerAgreementAccepted = Utils.isNotNull(accountState.disclaimerAcceptedForExternalProvidersLiveAccount)
                    ? accountState.disclaimerAcceptedForExternalProvidersLiveAccount : true;
            } else {
                accountState.disclaimerAcceptedForExternalProvidersLiveAccount = userProfile.IsSignalsDisclaimerAgreementAccepted;
            }
            Platform.dispatch(SetUserProfileInfo({
                userProfile
            }));
            Platform.dispatch(SetDisclaimerStatusReceivedForExternalProviders({}));
            ReadyState.hasThirdPartyDisclaimer = true;
            return Promise.resolve(answer[1] || {});
        }
        return Promise.resolve({});
    }

    public doFetchSettingsConfig = async (): Promise<void> => {
        const accountState: AccountState = Platform.state(ServiceType.Account);
        if (accountState.accountType === NXEnvironmentType.Live) {
            const languageCode: LangCode = LanguageUtil.languageCode();
            this.doFetchLeverages().catch(() => {});
            const request: GetChangeCloseOutMethodsRequest = {languageCode};
            const answer: [HttpReject, GetChangeCloseOutMethodsResponse] = await Utils.to(this.sendToComplianceService(request, "GetChangeCloseOutMethods"));
            if (answer[0]) {
                this._logger.warn("Failed fetch CloseOut methods");
            } else {
                const response: GetChangeCloseOutMethodsResponse = answer[1];
                if (Utils.isNotNull(response)){
                    const currentMethod: CloseOutMethod = response.CloseOutMethods?.filter(method => method.IsSelected)[0];
                    Platform.dispatch(SetAvailableCloseOutMethods({
                        isAllowed: response.IsAllowed,
                        methods: response.CloseOutMethods || [],
                        currentMethod
                    }));
                }
            }
        }
    }

    public doInsertClientPreference = async (payload: InsertClientPreferencePayload<any>): Promise<void> => {
        const authState: AuthState = Platform.state(ServiceType.Auth);
        const accountState: AccountState = Platform.state(ServiceType.Account);
        const serverType: ServerType = accountState.accountType === NXEnvironmentType.Live ? ServerType.TradingReal : ServerType.TradingDemo;
        const origin: string = Platform.config().servers[serverType];
        const preference: Preference<any> = payload.preference;
        const value: string = Preference.serialize(payload.key, preference);
        const result: boolean = await PreferencesManager.InsertPreference(origin, authState.token, accountState.accountId, accountState.accountType, payload.key, value);
        if (result) {
            Platform.dispatch(SetClientPreference({
                key: payload.key,
                preference
            }));
        }
        return Promise.resolve();
    }

    public doInsertClientPreferenceLS = async (payload: InsertClientPreferenceLSPayload<any>): Promise<void> => {
        const preference: Preference<any> = payload.preference;
        Platform.storage().setItem(payload.key, JSON.stringify(preference.value));
        Platform.dispatch(SetClientPreference({
            key: payload.key,
            preference
        }));
        return Promise.resolve();
    }

    public onSetTheme = async (payload: SetThemeTypePayload): Promise<void> => {
        Platform.storage().setItem(LSKey.Theme, payload.themeType);
        if (payload.persist) {
            Platform.dispatch(InsertClientPreference({
                key: LSKey.Theme,
                preference: Preference.Of(payload.themeType)
            }));
        }
    }

    private doFetchLeverages = async (): Promise<void> => {
        const languageCode: LangCode = LanguageUtil.languageCode();
        const request: GetChangeLeverageRequest = {languageCode};
        const answer: [HttpReject, GetChangeLeverageResponse] = await Utils.to(this.sendToComplianceService(request, "GetChangeLeverage"));
        if (answer[1]) {
            const {Success, AvailableLeverages, SelectedLeverage, ShouldAcceptDisclaimer} = answer[1];
            Platform.dispatch(SetAvailableLeverages({
                isAllowed: Success,
                leverages: AvailableLeverages || [],
                leverage: SelectedLeverage,
                shouldAcceptLeverageDisclaimer: ShouldAcceptDisclaimer
            }));
        }
    }

    public doSubmitLeverageDisclaimer = async ({action}: SubmitLeverageDisclaimerPayload): Promise<void> => {
        const languageCode: LangCode = LanguageUtil.languageCode();
        const request: AgreeLeverageDisclaimerRequest = {languageCode};
        Platform.dispatch(SetLoader({loaderType: LoaderType.FullScreen}));
        const answer: [HttpReject, GetChangeLeverageResponse] = await Utils.to(this.sendToComplianceService(request, "AcceptDisclaimer"));
        Platform.dispatch(HideLoader({}));
        if (answer[0]) {
            XhrUtil.notifyReject(answer[0]);
        } else {
            const {Success, AvailableLeverages, SelectedLeverage, ShouldAcceptDisclaimer} = answer[1];
            Platform.dispatch(SetAvailableLeverages({
                isAllowed: Success,
                leverages: AvailableLeverages || [],
                leverage: SelectedLeverage,
                shouldAcceptLeverageDisclaimer: ShouldAcceptDisclaimer
            }));
            if (Success) {
                Platform.dispatch(
                    SetTradingModal({
                        tradingModalType: TradingModalType.MyLeverageDisclaimer,
                        info: {
                            visible: false,
                        },
                    })
                );

                if (action) {
                    action();
                }

                Platform.dispatch(
                    SetTradingModal({
                        tradingModalType: TradingModalType.MyLeverage,
                        info: {
                            visible: true,
                        },
                    })
                );
            } else {
                Platform.dispatch(ShowPopup({
                    popup: {
                        type: PopupType.ERROR,
                        message: {
                            trKey: TranslationKey.errorGeneral
                        },
                        showClose: true,
                        icon: {type: PopupIconType.ERROR},
                        actions: [{type: PopupActionType.OK}]
                    }
                }));
            }
        }
    }

    public doChangeLeverage = async ({leverageId, action}: DoChangeLeveragePayload): Promise<void> => {
        const request: ChangeLeverageRequest = {selectedLeverageId: leverageId};
        Platform.dispatch(SetLoader({loaderType: LoaderType.FullScreen}));
        const answer: [HttpReject, {Message: string; Success: boolean}] = await Utils.to(this.sendToComplianceService(request, "ChangeCurrentLeverage"));
        Platform.dispatch(HideLoader({}));
        if (answer[0]) {
            XhrUtil.notifyReject(answer[0]);
        } else {
            if (answer[1].Success) {
                const {leverages} = Platform.reduxState<StoreState>().settings;
                const leverage: Leverage = leverages?.filter(l => l.Id === leverageId)[0];
                Platform.dispatch(SetCurrentLeverage({
                    leverage
                }));
                Platform.dispatch(
                    SetTradingModal({
                        tradingModalType: TradingModalType.MyLeverage,
                        info: {
                            visible: false,
                        },
                    })
                );
                Platform.dispatch(ShowPopup({
                    popup: {
                        type: PopupType.INFO,
                        icon: {type: PopupIconType.INFO},
                        message: {
                            trKey: TranslationKey.leverageChanged,
                            params: [Parameter.Of(TranslationParam.value, `1 : ${leverage.Value}`)]
                        },
                        showClose: true,
                        actions: [{type: PopupActionType.OK}]
                    }
                }));
                if (action) {
                    action();
                }
            } else {
                Platform.dispatch(ShowPopup({
                    popup: {
                        type: PopupType.ERROR,
                        message: {
                            trKey: TranslationKey.errorGeneral
                        },
                        showClose: true,
                        icon: {type: PopupIconType.ERROR},
                        actions: [{type: PopupActionType.OK}]
                    }
                }));
            }
        }
    }

    public doChangeCloseOutMethod = async ({methodId, action}: DoChangeCloseOutMethodPayload): Promise<void> => {
        const request: ChangeCloseOutMethodRequest = {selectedCloseOutMethod: methodId};
        Platform.dispatch(SetLoader({loaderType: LoaderType.FullScreen}));
        const answer: [HttpReject, {Message: string; Success: boolean}] = await Utils.to(this.sendToComplianceService(request, "ChangeCurrentCloseOutMethod"));
        Platform.dispatch(HideLoader({}));
        if (answer[0]) {
            XhrUtil.notifyReject(answer[0]);
        } else {
            if (answer[1].Success) {
                const {closeOutMethods} = Platform.reduxState<StoreState>().settings;
                const currentMethod: CloseOutMethod = closeOutMethods?.filter(method => method.Value === Number(methodId))[0];
                Platform.dispatch(SetCurrentCloseOutMethod({
                    currentMethod
                }));
                Platform.dispatch(
                    SetTradingModal({
                        tradingModalType: TradingModalType.CloseOutMethod,
                        info: {
                            visible: false,
                        },
                    })
                );
                Platform.dispatch(ShowPopup({
                    popup: {
                        type: PopupType.INFO,
                        icon: {type: PopupIconType.INFO},
                        message: {
                            trKey: TranslationKey.closeOutMethodChanged,
                            params: [Parameter.Of(TranslationParam.value, currentMethod?.Text)]
                        },
                        showClose: true,
                        actions: [{type: PopupActionType.OK}]
                    }
                }));
                if (action) {
                    action();
                }
            } else {
                Platform.dispatch(ShowPopup({
                    popup: {
                        type: PopupType.ERROR,
                        message: {
                            trKey: TranslationKey.errorGeneral
                        },
                        showClose: true,
                        icon: {type: PopupIconType.ERROR},
                        actions: [{type: PopupActionType.OK}]
                    }
                }));
            }
        }
    }

    public doSubmitAccountAnnualReport = async ({year}: SubmitAccountAnnualReportPayload): Promise<void> => {
        this._logger.debug("Do submit annual report for: " + year);
        const accountState: AccountState = Platform.state(ServiceType.Account);
        const request: GetAccountLogRequest = {
            AccountId: accountState.accountId,
            from: "{year}-01-01T00:00:00.000Z".replace("{year}", year.toString()),
            to: "{year}-12-31T00:00:00.000Z".replace("{year}", year.toString()),
            filterActionTypes: []
        };
        Platform.dispatch(SetLoader({loaderType: LoaderType.FullScreen}));
        const answer: [HttpReject, []] = await Utils.to(XhrUtil.sendAuthenticated(request, "TradeServer/SearchLogProvider.svc/json/GetAccountDealingLog"));
        Platform.dispatch(HideLoader({}));
        if (answer[0]) {
            XhrUtil.notifyReject(answer[0]);
        } else {
            const {userProfile} = Platform.reduxState<StoreState>().settings;
            let email: string = userProfile?.Email;
            if (Utils.isEmpty(email)) {
                const profile: UserProfileInfo = await this.doFetchUserProfile();
                email = profile?.Email;
            }
            Platform.dispatch(ShowPopup({
                popup: {
                    type: PopupType.INFO,
                    message: {
                        trKey: Utils.isNotNull(answer[1]) ? TranslationKey.annualReportSuccess : TranslationKey.annualReportPending,
                        params: [Parameter.Of(TranslationParam.value, email)]
                    },
                    showClose: true,
                    icon: {type: PopupIconType.SUCCESS},
                    actions: [{type: PopupActionType.OK}]
                }
            }));
        }
    }

    public doFetchVaultDetails = async () => {
        const accountState: AccountState = Platform.state(ServiceType.Account);
        const request: GetVaultDetailsRequest = {
            AccountId: accountState.accountId
        };
        const answer: [HttpReject, GetVaultDetailsResponse] = await Utils.to(XhrUtil.sendToAccountService(request, "GetVaultDetailsv2"));
        if (answer[0]) {
            this._logger.debug("Failed fetch vault details");
        } else {
            Platform.dispatch(SetVaultDetails({
                vault: answer[1] || {}
            }));
        }
    }

    public doRollingContractEnabled = async () => {
        const answer: [HttpReject, IsRollingEnabledResponse] = await Utils.to(this.sendToWebProfitService(null, "IsRollingEnabledv2"));
        if (answer[0]) {
            this._logger.debug("Failed fetch rolling status");
        } else {
            Platform.dispatch(SetIsRollingContractsEnabled({
                enabled: answer[1]?.IsRollingEnabled,
                expiration: answer[1]?.RollingContractsExpirationDate
            }))
        }
    }

    public doAcceptDisclaimerForExternalProviders = async (payload: AcceptDisclaimerForExternalProvidersPayload) => {
        const request: SignalsDisclaimerAgreementRequest = {};
        Platform.dispatch(SetLoader({loaderType: LoaderType.FullScreen}));
        const answer: [HttpReject, SignalsDisclaimerAgreementResponse] = await Utils.to(this.sendToWebProfitService(request, "SubmitSignalsDisclaimerAgreement"));
        Platform.dispatch(HideLoader({}));
        if (answer[0]) {
            XhrUtil.notifyReject(answer[0]);
        } else {
            if (answer[1].Success) {
                const accountState: AccountState = Platform.state(ServiceType.Account);
                accountState.disclaimerAcceptedForExternalProvidersLiveAccount = true;
                Platform.dispatch(SetDisclaimerAcceptedForExternalProviders({}));
                Platform.dispatch(
                    SetTradingModal({
                        tradingModalType: TradingModalType.SignalsDisclaimer,
                        info: {
                            visible: false,
                        },
                    })
                );
                if (payload.action) {
                    payload.action();
                }
            } else {
                Platform.dispatch(ShowPopup({
                    popup: {
                        type: PopupType.ERROR,
                        message: {
                            customValue: answer[1].Error
                        },
                        showClose: true,
                        icon: {type: PopupIconType.ERROR},
                        actions: [{type: PopupActionType.OK}]
                    }
                }));
            }
        }
    }

    public doFetchCanSkipEducationTradingGroup = async (): Promise<void> => {
        const accountState: AccountState = Platform.state(ServiceType.Account);
        if (accountState.accountType === NXEnvironmentType.Live) {
            const answer: [HttpReject, boolean] = await Utils.to(this.sendToTradeService(accountState.accountId, "CanSkipEducationTradingGroup"));
            if (answer[0]) {
                this._logger.debug("Failed fetch can skip education trading group");
            } else {
                Platform.dispatch(SetCanSkipEducationTradingGroup({
                    value: answer[1]
                }));
            }
        }
    }

    public doSkipEducationTradingGroup = async (): Promise<void> => {
        Platform.dispatch(SetLoader({loaderType: LoaderType.FullScreen}));
        const accountState: AccountState = Platform.state(ServiceType.Account);
        const request: SkipEducationTradingGroupRequest = {
            UserAccountId: accountState.accountId
        };
        const answer: [HttpReject, any] = await Utils.to(this.sendToTradeService(request, "SkipEducationTradingGroup"));
        Platform.dispatch(HideLoader({}));
    }

    public doNotifyNotAvailableForPushNotifications = async (): Promise<void> => {
        Platform.dispatch(ShowPopup({
            popup: {
                type: PopupType.INFO,
                message: {
                    trKey: TranslationKey.allowNotificationsWarning,
                    params: [Parameter.Of(TranslationParam.brand, Platform.config().brand)]
                },
                showClose: true,
                icon: {type: PopupIconType.INFO},
                actions: [{type: PopupActionType.OK}]
            }
        }));
    }

    public doRegisterPushToken = async ({platformType, packageName, pushNotificationToken}: RegisterPushTokenPayload): Promise<void> => {
        this._logger.debug("Do register push notification token. Platform type " + platformType + " Package name: " + packageName + " Token " + pushNotificationToken);
        const request: AddPushNotificationTokenRequest = {
            PlatformType: platformType,
            PackageName: packageName,
            PushNotificationToken: pushNotificationToken
        };
        const answer: [HttpReject, any] = await Utils.to(this.sendToWebProfitMobile(request, "AddPushNotificationToken"));
        if (answer[0]) {
            this._logger.debug("Failed register push notification token. Status: " + answer[0].status);
        } else {
            this._logger.debug("Registered push notification. Response: " + JSON.stringify(answer[1]));
        }
    }

    public doGetEMTPercentage = async (): Promise<void> => {
        this._logger.debug("Fetch EMT percentages");
        const request: GetEMTPercentagesRequest = {};
        const answer: [HttpReject, number[]] = await Utils.to(this.sendToWebProfitService(request, "GetExposureManagementToolPercentages"));
        if (answer[0]) {
            this._logger.warn("Failed fetch EMTPercentages");
        } else {
            Platform.dispatch(SetEMTPercentages({values: answer[1] || []}));
        }
    }

    public doSubmitEMTPercentage = async ({value}: NumberPayload): Promise<void> => {
        this._logger.debug(`Submit EMT percentage: ${value}`);
        const emtPercentage: number = Platform.reduxState<StoreState>().account.EMTPercentage;
        const request: SetEMTPercentageRequest = {
            ExposureManagementPercentage: value
        };
        Platform.dispatch(SetLoader({loaderType: LoaderType.FullScreen}));
        const answer: [HttpReject, SetEMTPercentageResponse] = await Utils.to(this.sendToWebProfitService(request, "SaveExposureManagementToolPercentages"));
        Platform.dispatch(HideLoader({}));
        if (answer[0]) {
            XhrUtil.notifyReject(answer[0]);
        } else {
            Platform.dispatch(SetTradingModal({
                tradingModalType: TradingModalType.EMT,
                info: {
                    visible: false
                }
            }));

            let trKey: TranslationKey;
            let enabled: boolean = true;
            if (!Utils.greaterThen0(emtPercentage) && Utils.greaterThen0(value)) {
                trKey = TranslationKey.EMTEnabled;
            } else if (Utils.greaterThen0(emtPercentage) && !Utils.greaterThen0(value)) {
                trKey = TranslationKey.EMTDisabled;
                enabled = false;
            } else {
                trKey = TranslationKey.EMTUpdated;
            }
            Platform.bi().track(enabled ? BIEventType.EMTEnabled : BIEventType.EMTDisabled, {
                Percentage: value
            });
            Platform.dispatch(ShowPopup({
                popup: {
                    type: PopupType.INFO,
                    message: {trKey},
                    showClose: true,
                    icon: {type: PopupIconType.INFO},
                    actions: [{type: PopupActionType.OK}]
                }
            }));
        }
    }

    public doFetchNotificationData = async (): Promise<PushMessage[]> => {
        const request: GetPushNotificationDataRequest = {};
        const answer: [HttpReject, GetPushNotificationDataResponse] = await Utils.to(this.sendToWebProfitMobile(request, "GetLastMessages"));
        if (answer[0]) {
            this._logger.debug("Failed fetch latest push messages");
        } else if (Utils.isArrayNotEmpty(answer[1].Messages)) {
            return answer[1].Messages;
        }
        return [];
    }

    public doFetchTransactionsToSign = async (): Promise<void> => {
        this._logger.debug("Fetch deposit transactions to sign");
        Platform.dispatch(SetTransactionsToSign({
            transactions: []
        }));
        Platform.dispatch(SetLoader({loaderType: LoaderType.FullScreen}));
        const answer: [HttpReject, GetPendingTransactionsToSignResponse] = await Utils.to(this.sendToMobileBillingService({}, "GetPendingTransactionsToSign"));
        Platform.dispatch(HideLoader({}));
        if (answer[0]) {
            XhrUtil.notifyReject(answer[0]);
        } else {
            Platform.dispatch(SetTransactionsToSign({
                transactions: answer[1].PendingTransactions || []
            }));
        }
    }

    public doSignTransactions = async ({signature, transactions, action}: TransactionsToSignPayload): Promise<void> => {
        this._logger.debug(`Try sign transactions. Has signature: ${Utils.isNotEmpty(signature)}`);
        const request: SignTransactionsRequest = {
            TransactionIds: transactions.map(t => t.TransactionId),
            SignatureImageBase64: signature
        };
        Platform.dispatch(SetLoader({loaderType: LoaderType.FullScreen}));
        const answer: [HttpReject, SignTransactionsResponse] = await Utils.to(this.sendToMobileBillingService(request, "SignTransactions", 300000));
        Platform.dispatch(HideLoader({}));
        if (answer[0]) {
            XhrUtil.notifyReject(answer[0]);
        } else {
            if (action) {
                action();
            } else {
                Platform.dispatch(
                    SetTradingModal({
                        tradingModalType: TradingModalType.SignDeclaration,
                        info: {
                            visible: false,
                        },
                    })
                );
            }
            Platform.dispatch(SetDeclarationRecorded({value: true}));
        }
    }

    public doUpdateLanguage = async ({langCode}: UpdatePlatformLanguagePayload): Promise<void> => {
        const accountState: AccountState = Platform.state(ServiceType.Account);
        const request: UpdatePlatformLanguageRequest = {
            UserId: accountState.userId,
            LanguageCode: langCode
        };
        await Utils.to(this.sendToWebProfitService(request, "UpdatePlatformLanguage"));
    }

    private doFetchTelegramInitUrl = async (): Promise<void> => {
        const answer: [HttpReject, GetTelegramInitURLResponse] = await Utils.to(this.sendToWebProfitService(null, "GenerateTelegramChatUrl"));
        if (answer[0]) {
            this._logger.debug("Failed fetch Telegram init url");
        } else {
            const telegramInitUrl: string = answer[1].TelegramInitUrl
            if (telegramInitUrl) {
                Platform.dispatch(SetTelegramUrl({telegramInitUrl}));
            }
        }
    }

    private sendToWebProfitMobile = (request: any, path: string): Promise<any> => {
        return XhrUtil.sendAuthenticated(request, "WebProfitServer/MobileService.svc/json/" + path);
    }

    private sendToMobileBillingService = (request: any, path: string, timeout?: number): Promise<any> => {
        return XhrUtil.sendAuthenticatedTo(ServerType.Billing, request, "BillingServer/MobileBillingService2.svc/json/" + path, null, false, timeout);
    }

    private sendToTradeService = (request: any, path: string): Promise<any> => {
        return XhrUtil.sendAuthenticated(request, "TradeServer/TradeService.svc/json/" + path);
    }

    private sendToComplianceService = (request: any, path: string): Promise<any> => {
        return XhrUtil.sendAuthenticatedTo(ServerType.Compliance, request, "ComplianceWebSite/ComplianceFormService.svc/json/" + path);
    }

    private sendToWebProfitService = (request: any, path: string): Promise<any> => {
        return XhrUtil.sendAuthenticated(request, "WebProfitServer/WebProfitClientService.svc/json/" + path, null, true);
    }
}
