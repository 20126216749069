import Platform from "platform/Platform";
import {Configuration} from "core/configuration/Configuration";
import {EnvType} from "platform/enum/EnvType";
import Utils from "platform/util/Utils";
import {StoreState} from "core/redux/StoreState";

export class ConfigUtil {

    public static UseAddressBarDomain = (url: string): string => {
        if (url) {
            const {nativeUseDomain} = Platform.reduxState<StoreState>().app;
            let parts: string [];
            if (Platform.environment().type() === EnvType.Web && Platform.config<Configuration>().useAddressBarDomain) {
                parts = window.location.hostname?.split(".");
            } else if (Platform.environment().type() === EnvType.Native && Utils.isNotEmpty(nativeUseDomain)) {
                parts = nativeUseDomain.split(".");
            }
            if (Utils.isArrayNotEmpty(parts)) {
                const hostname: string = /(.+:\/\/)?([^\/]+)(\/.*)*/i.exec(url)[2];
                const hostnameParts: string [] = hostname.split(".");
                hostnameParts[hostnameParts.length - 2] = parts[parts.length - 2];
                hostnameParts[hostnameParts.length - 1] = parts[parts.length - 1];
                return url.replace(hostname, hostnameParts.join("."));
            }
        }
        return url;
    }
}
